import React, { Fragment } from 'react'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import darkTheme from '@/src/theme'
function NotFound() {
    return (
    <Fragment>
        <Stack sx={{            
            display: 'flex',
            justifyContent: 'center',
            py: 15,
            textAlign: 'center',
            color: darkTheme.palette.primary.main
        }}>
            <Typography variant="h1">404</Typography>
            <Typography variant="h6" sx={{color: darkTheme.palette.common.black}}>The Resource Was Not Found</Typography>    
        </Stack>
    </Fragment>
  )
}

export default NotFound